<template>
  <div>
    <div class="mt-2">
      <a-tabs type="card" class="w-full" @change="changeTabs" :activeKey="activeTab">
        <a-tab-pane :key="0" tab="产品销售数据"></a-tab-pane>
        <a-tab-pane :key="1" tab="未来5天待交付产品"></a-tab-pane>
      </a-tabs>
    </div>

    <!-- 搜索条件 -->
		<div style="padding: 20px 10px;">
			<a-form-model layout="inline" ref="searchRef">
				<a-form-model-item prop="outlets_city_adcode" label="城市市场">
					<a-select v-model="outlets_city_adcode" style="width:200px;">
						<a-select-option v-if="!isCityManage" value="">全部</a-select-option>
						<a-select-option v-for="(item, index) in manageCityList" 
							:key="index" 
							:value="item.adcode">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-model-item>
				<template v-if="activeTab==0">
					<a-form-model-item prop="order_date" label="下单日期">
						<a-range-picker
							style="width:300px"
							v-model="order_date"
							valueFormat="YYYY-MM-DD"
						/>
					</a-form-model-item>
					<a-form-model-item prop="finish_date" label="完成日期">
						<a-range-picker
							style="width:300px"
							v-model="finish_date"
							valueFormat="YYYY-MM-DD"
						/>
					</a-form-model-item>
				</template>
					
				<a-form-model-item>
					<a-button type="primary" html-type="submit" @click="submit">查询</a-button>
				</a-form-model-item>
			</a-form-model>
		</div>

		<!-- 销售数据 -->
		<div v-if="activeTab==0">
			<base-table 
				:columnsData="columns_citysales"
				:tableData="tableData_citysales"
			></base-table>
		</div>

		<!-- 未来5天 -->
    <template v-if="activeTab==1">
			<base-table
				:columnsData="columns_daily"
				:tableData="tableData_daily"
			></base-table>
    </template>

  </div>
</template>

<script>
import { 
	skuDailyDeliver, 
	skuCitySales,
} from "@/api/finance/analysis.js"
import { getFinanceCityList } from "@/api/outlets"

export default {
  components: {  },
  data() {
    return {
			activeTab: 0,

      isCityManage: false,
      manageCityList: [],

			outlets_city_adcode: "",
			order_date: [],
			finish_date: [],

			tableData_citysales: [],
			columns_citysales: [],

			tableData_daily: [],
			columns_daily: [],

			columns_base: [
				{
					title: "产品",
					dataIndex: "goods_name",
					align: "center",
				},
				{
					title: "口味",
					dataIndex: "flavor_name",
					align: "center",
				},
				{
					title: "尺寸",
					dataIndex: "specification_name",
					align: "center",
				},
			],

    }
  },
  async mounted() {
    this.order_date = [
			this.getToday(),
			this.getToday(),
		]
    await this.initFinanceCityList()
		this.changeTabs(this.activeTab)
  },
  methods: {

    // 内部管理的城市列表
    async initFinanceCityList () {
      let list
      try{
        list = JSON.parse(localStorage.getItem('cityList'))
      }catch(e){
        console.log(e)
      }
      // 如果是城市市场账号
      if(list && list.length) {
        this.isCityManage = true
        this.manageCityList = list
        this.outlets_city_adcode = this.manageCityList[0].adcode
      }else{
      // 否则全部城市
        const { code, data } = await getFinanceCityList()
        if (code == 0) {
          this.manageCityList = data.list
        }
      }
    },

    submit(){
			if(this.activeTab == 0){
				this.handlerSearchCity()
			}

			if(this.activeTab == 1){
				this.getSkuDailyDeliver()
			}
    },

    changeTabs(v){
      this.activeTab = v

      if(this.activeTab == 0){
				this.handlerSearchCity()
      }

      if(this.activeTab == 1){
				this.getSkuDailyDeliver()
      }
    },

    async getSkuCitySales(params) {

			const { data, code } = await skuCitySales(params)
			if(code != 0) return

			let cityList = data.list[0] && data.list[0].outlets_city_sales_record_list
			let columns = []
			let totalItem = {
				goods_name: "全部产品",
				flavor_name: "——",
				specification_name: "——",
			}

			data.list.map(el=>{
				let sku_allcity_num = 0
				el.outlets_city_sales_record_list.map(c=>{
					sku_allcity_num += c.number
				})
				el.outlets_city_sales_record_list.unshift({
					number: sku_allcity_num,
					outlets_city_name: "全部城市"
				})
				return el
			})

			cityList && cityList.map((day, index)=>{
				columns.push({
					title: day.outlets_city_name,
					dataIndex: "city_"+index,
					align: "center",
					width: 80,
				})
				totalItem["city_"+index] = 0

				data.list.map(el=>{
					let sku_city_num = el.outlets_city_sales_record_list[index].number
					totalItem["city_"+index] += sku_city_num
					el["city_"+index] = sku_city_num
					return el
				})

			})
			this.columns_citysales = this.columns_base.concat(columns)
			// this.tableData_daily = data.list.concat([totalItem])
			this.tableData_citysales = [totalItem].concat(data.list)
    },

    async getSkuDailyDeliver() {
			const params = {
				outlets_city_adcode: this.outlets_city_adcode,
				time_start: this.getToday(-1) + '00:00:00',
				time_end:   this.getToday(4) + '23:59:59'
			}
			const { data, code } = await skuDailyDeliver(params)
			if(code != 0) return

			let dateList = data.list[0] && data.list[0].daily_deliver_list
			let columns = []
			let totalItem = {
				goods_name: "总计"
			}
			dateList && dateList.map((day, index)=>{
				columns.push({
					title: index == 0 ? "昨天" : ( index == 1 ? "今天" : day.date ),
					dataIndex: "date_"+index,
					align: "center",
				})
				totalItem["date_"+index] = 0
				data.list.map(el=>{
					let sku_day_num = el.daily_deliver_list[index].number
					totalItem["date_"+index] += sku_day_num
					el["date_"+index] = sku_day_num
					return el
				})

			})
			this.columns_daily = this.columns_base.concat(columns)
			// this.tableData_daily = data.list.concat([totalItem])
			this.tableData_daily = [totalItem].concat(data.list)
    },

    // 搜索城市售卖数据
		handlerSearchCity() {
			if(this.order_date.length < 2 && this.finish_date.length < 2){
				this.$message.warning("下单日期和完成日期至少填写一项")
				return
			}
			const params = {
				outlets_city_adcode: this.outlets_city_adcode
			}
			
			if(this.order_date.length == 2){
				if(new Date(this.order_date[1]).getTime() - new Date(this.order_date[0]).getTime() > 62 * 24 * 3600 * 1000){
					this.$message.warning("下单时间跨度不能超过2个月")
					return
				}
				if(this.order_date.length == 2){
					Object.assign(params, {
						time_start: this.order_date[0] + " 00:00:00",
						time_end:   this.order_date[1] + " 23:59:59",
					})
				}
			}
			if(this.finish_date.length == 2){
				if(new Date(this.finish_date[1]).getTime() - new Date(this.finish_date[0]).getTime() > 62 * 24 * 3600 * 1000){
					this.$message.warning("完成时间跨度不能超2个月")
					return
				}
				if(this.finish_date.length == 2){
					Object.assign(params, {
						finish_time_start: this.finish_date[0] + " 00:00:00",
						finish_time_end:   this.finish_date[1] + " 23:59:59",
					})
				}
			}

			this.getSkuCitySales(params)
		},

		// -----------
		getToday(n){
			n = n || 0
			let day = new Date()
			if(n){
				day.setTime(day.getTime() + n*24*3600*1000)
			}
			return this.formatDate(day)
		},

		getLastMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			if(month == 0){
				month = 11
				year = year-1
			}else{
				month = month-1
			}
			return this.formatDate(new Date(year, month, 1))
		},
		getLastMonthLastDay(){
			let date = new Date()
			date.setTime(date.getTime() - date.getDate()*24*3600*1000)

			return this.formatDate(date)
		},

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style>
</style>